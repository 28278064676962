import React from 'react';
import axios from 'axios'
import { withRouter } from "react-router-dom";
import {NavLink, TabContent, TabPane, Nav, NavItem, Container, Row, Col, Card, CardTitle, Form, FormGroup, Button, Input, CardSubtitle, Label, Spinner} from 'reactstrap';
import StarRatingComponent from 'react-star-rating-component';
import classnames from 'classnames';
import ImageCarousel from './ImageViewer';

var BASEURL = "13v4yjfvyi.execute-api.us-east-1.amazonaws.com";

class ReviewSection extends React.Component {
    constructor(props){
        super(props)
        this.state = {...this.props.data, activeTab: '1', imagesRequested: false, googlereviews: [], localreviews: this.props.data.details.reviews, showreviews: true, uploadedFiles: []}
        this.toggle = this.toggle.bind(this);
    }

    toggle(tab) {

        if (tab === '2' && !this.state.imagesRequested){
            this.setState({showreviews: false})
            this.setState({imagesRequested: true})
        }
        if (this.state.activeTab !== tab) {
            this.setState({
              activeTab: tab
            });
          }
    }

    handleChange = (e) =>{
        if (e.target.id === 'comment'){
            this.setState({comment: e.target.value, displayCommentMessage: false, displayRatingMessage: false})
        }   
    }

    onStarClick(nextValue) {
        this.setState({reviewRating: nextValue});
      }

    handleFileUpload = (event) => {
        const maxSize = 15728640;
        this.setState({uploadedFiles: []})
        let acceptedFiles = Array.from(event.target.files)
        let overSizedFiles = []
        acceptedFiles.forEach(f => {
            if (f.size > maxSize){
                console.log("file " + f.name + " is too large")
                overSizedFiles.push(f)
            }
        })
        if (overSizedFiles.length > 0){
            overSizedFiles.forEach(f => {
                acceptedFiles.splice(acceptedFiles.indexOf(f),1)
            })
            alert("file(s) too large!")
        }

        this.setState((prevState) => ({
          uploadedFiles: [...prevState.uploadedFiles, ...acceptedFiles],
        }));
      };

    handleSubmit = async (e) =>{
        if( this.state.comment.length > 500){
            e.preventDefault();
            this.setState({displayCommentMessage: true, displayRatingMessage: false})
        }
        else{
            if(this.state.reviewRating<1){
                e.preventDefault()
                this.setState({displayCommentMessage: false, displayRatingMessage: true})
            }
            else{
                e.preventDefault();
                this.setState({showButton: false}); 
                if(this.state.uploadedFiles.length === 0){
                    axios.post(`https://${BASEURL}/addCommentToRestaurant`,{data: this.state.details, comment: this.state.comment, rating: this.state.reviewRating, createdAt: new Date().toLocaleString()}).then(data=>{  
                        if(data.status < 399){
                            this.setState({successMessage: true, errorMessage: false, displayCommentMessage: false, displayRatingMessage: false})  
                        }else {
                            this.setState({successMessage: false, errorMessage: true, displayCommentMessage: false, displayRatingMessage: false}) 
                        }
                        }).catch(err=>{
                            this.props.history.push('/restaurants')
                            console.log(err)
                        })
                }else{
                    try { 
                        let listOfImageIds =[]
                        for (const file of this.state.uploadedFiles){
                            await uploadFileToS3(file)
                            listOfImageIds.push(file.customId)
                        }

                        await axios.post(`https://${BASEURL}/addCommentToRestaurantWithImages`,{data: this.state.details, newImages: listOfImageIds, comment: this.state.comment, rating: this.state.reviewRating, createdAt: new Date().toLocaleString()}).then(data=>{ 
                            this.setState({successMessage: true, errorMessage: false, displayCommentMessage: false, displayRatingMessage: false, uploadedFiles: []})  
                            console.log("Review successfully updated!")    
                        }).catch(err=>{
                                this.props.history.push('/restaurants')
                                this.setState({successMessage: false, errorMessage: true, displayCommentMessage: false, displayRatingMessage: false, uploadedFiles: []})  
                                console.log(err)
                            })

                    } catch (error) {
                        console.error('An Error occurred: ', error);
                    }
            }
            }
        }
    }

    render(){
        const reviewRating  = this.state.reviewRating;
        var review = (<div key={0}></div>);
        if(this.state.detailsLoaded){
            let reviewType = (this.state.activeTab === '2'?this.state.googlereviews:this.state.details.reviews);
            review = reviewType.map((review, idx) =>{
                let dateCreated = review.createdAt;
                if(review.approved && review.comment ){
                return(
                    <div key={idx} className="my-2 mx-2">
                        <Row className="d-flex justify-content-center">
                            <Col lg={{size: 9}}>
                                <Card>
                                    <Container className="mx-2 my-2">
                                        <div className="my-2"></div>
                                        {review.comment?
                                        <CardSubtitle>
                                            <h5>{review.comment}</h5>
                                        </CardSubtitle>
                                        : 
                                        null} 
                                        <CardSubtitle className="mx-3">
                                            <Row >
                                                Rating: <StarRatingComponent emptyStarColor="gray" value={parseFloat(review.rating)} editable={false} name={`reviewRating${idx}`}/>
                                            </Row>        
                                        </CardSubtitle>                                 
                                            <p>{dateCreated}</p> 
                                    </Container>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    )
                }
                else {
                return (<div key={idx}></div>)
                }
            }
            )
        }

        return (
            <Container>
                 <div id="map" hidden></div> 
                <Nav tab="true" >
                    <NavItem>
                        <NavLink
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => {this.toggle('1')}}
                        >Reviews</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                        className={classnames({ active: this.state.activeTab === '2' })}
                        onClick={() => {this.toggle('2')}}
                        >Images</NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <Row className="d-flex justify-content-center" >
                            <Col lg={{size: 9}}>
                                <Card >
                                    <Container className="my-2">
                                    <CardTitle>
                                        <h6>Leave a Review: </h6>
                                    </CardTitle>
                                    <Col>
                                    <Row>
                                        Rating: <StarRatingComponent 
                                            name="reviewRating" 
                                            starCount={5}
                                            value={reviewRating}
                                            onStarClick={this.onStarClick.bind(this)}
                                        />                                          
                                    </Row>
                                    </Col>

                                    <Form onSubmit={this.handleSubmit} onChange={this.handleChange}>
                                        <FormGroup>
                                            {this.state.showButton
                                            ?
                                            <Input id="comment" type="textarea" placeholder={`Details on your experience at ${this.state.details.name} (Optional)`}/>
                                            :
                                            null
                                            }
                                           
                                            {this.state.displayCommentMessage?<h6>Comment must be less than 500 characters!</h6>:null}
                                            {this.state.displayRatingMessage?<h6>Rating must have at least 1 star!</h6>:null}
                                            {this.state.successMessage?<h6>Thank you for your feedback!</h6>:null}
                                            {this.state.errorMessage?<h6>There was an error with your request!</h6>:null}
                                            {(!this.state.successMessage && !this.state.errorMessage && !this.state.showButton)?<h6>Submitting   <Spinner size='sm'/> </h6>:null}
                                            {this.state.showButton
                                            ?
                                            <Row>
                                                <Col>
                                                    <Button className="my-2" name="reviewButton" color='dark'>Submit</Button>
                                                </Col>
                                                <Col className="d-flex justify-content-end">
                                                    <div className="image-upload">
                                                        <Input id="file-upload" type="file" accept="image/*" onChange={this.handleFileUpload} multiple />
                                                        <Label for="file-upload">
                                                            <img className="upload-images-button" src={require("../upload-image.png")} alt="DZR"/>
                                                        </Label>
                                                    </div>
                                                </Col>
                                            </Row>
                                            :
                                            null}
                                        </FormGroup>
                                    </Form>                                        
                                    </Container>
                                </Card>
                            </Col>
                        </Row>
                        {review}
                    </TabPane>
                    <TabPane tabId="2">
                        <div className="my-2 mx-2">
                            <ImageCarousel images={this.state.details.images} />
                        </div>
                    </TabPane>
                </TabContent>
            </Container>
        )
    }
}

 async function uploadFileToS3(file){
    try {
        const data = await axios.get(`https://${BASEURL}/getPresignedImageURL`).catch(err => {console.log("There was an error getting your presignedURL")});
        const {uploadURL, Key} = data.data;      

        await fetch(uploadURL,{method: "PUT", body: file}).then(() => {
            file.customId = Key
        }).catch(err => {
            console.log(err)
        })   
    } catch {
        this.setState({displayCommentMessage: false, displayRatingMessage: true})
        throw new Error('Failed to fetch signed URL');
    }
  }

export default withRouter(ReviewSection)