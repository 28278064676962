import React, { Component } from 'react';

class ImageViewer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentIndex: null,
      isFullscreen: false,
    };
  }

  openFullscreen = (index) => {
    this.setState({
      currentIndex: index,
      isFullscreen: true,
    });
  };

  closeFullscreen = () => {
    this.setState({
      currentIndex: null,
      isFullscreen: false,
    });
  };

  render() {
    const { images } = this.props;
    const { currentIndex, isFullscreen } = this.state;

    return (
      <div className="image-carousel">
        {images.map((image, index) => (
          <div
            key={index}
            className="image-wrapper"
            onClick={() => this.openFullscreen(index)}
          >
            <img src={image} alt={`${index}`} />
          </div>
        ))}
        {isFullscreen && (
          <div className="fullscreen-overlay" onClick={this.closeFullscreen}>
            <img src={images[currentIndex]} alt={`${currentIndex}`} />
          </div>
        )}
      </div>
    );
  }
}

export default ImageViewer;
